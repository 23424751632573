import React from 'react';
import AppBar from './components/AppBar';
import Footer from './components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { FaPhone, FaWhatsapp, FaShoppingCart } from 'react-icons/fa';
import { Buttons } from '../components/Buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { motion } from 'framer-motion';
import './Home.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./brand.css";

const Home = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
  };
  const brands = [
    { name: 'Standard', logo: 'https://bijilicrackers.co/assets/images/brands/img_standard.png' },
    { name: 'Kaliswari', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlD50Uvxd86-W6I2B3rmlVXoxs5QVf0S6g5g&s' },
    { name: 'Krishna', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhwg5UK7mX0v0_AM6bTUtHTS_RdkEa_DdwPg&s' },
    { name: 'Vadivel', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8vHuYtHNJ39kylvSlv7lQcroAC_giet__yQ&s' },
    // Add more brands here
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    
    <>
      <AppBar />

      {/* Intro Section */}
      <Container>
        <Row>
          <Col lg={6} xs={12} className='py-3 align-self-center'>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <h1 className="bold pt-5">Welcome to King Crackers</h1>
              <p className="regular">
                We supply quality crackers at the lowest price. Our wide variety of firecrackers include single and multi-sound crackers, sparklers, ground chakkars, flower pots, twinkling stars, and much more. Our products are known for their safety and reliability, perfect for all types of occasions.
              </p>
            </motion.div>
          </Col>
          <Col lg={6} className='py-2'>
            <motion.img
              src={require('../assets/images/intro1.jpg')}
              className='img-fluid rounded shadow-xl'
              alt='King Crackers'
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            />
          </Col>
        </Row>
      </Container>

      {/* Parallax Section */}
      <Parallax
       bgImage="https://images.unsplash.com/photo-1544551763-ced60f4f3d18?fit=crop&w=1200&q=80"
        strength={500}
        className='parallax-section'
      >
         <div className="brands-section">
      <h2 className="text-center text-black">Our Brands</h2>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <div key={index} className="brand-logo-container">
            <img src={brand.logo} alt={brand.name} className="brand-logo" />
          </div>
        ))}
      </Slider>
    </div>
      </Parallax>

      {/* Products Section */}
      <Container className='py-5 product-section'>
        <Row>
          <Col lg={12} xs={12} className='text-center py-3'>
            <h2 className='bold'>Our Products</h2>
            <p className='regular'>
              Our motto is to make every festival celebration bright and safe. We bring this out with our wide range of firecrackers. Our products are known for their safety and timely delivery at economical pricing.
            </p>
          </Col>
          {[
            { img: 'chakar.webp', name: 'Ground Chakkars' },
            { img: 'gaint.webp', name: 'Gaints' },
            { img: 'pots.webp', name: 'Flower Pots' },
            { img: 'rocket.webp', name: 'Rockets' },
            { img: 'singlesound.webp', name: 'Single Sounds' },
            { img: 'sparkle.webp', name: 'Sparkles' },
            { img: 'skyshots.webp', name: 'Sky Shots' },
            { img: 'kids.webp', name: 'Kids Special' },
          ].map((product, index) => (
            <Col key={index} lg={3} md={3} xs={12} className='py-3 text-center'>
              <motion.div
                className='product-card'
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <div className='product-container pb-3'>
                  <img src={require(`../assets/images/${product.img}`)} className='img-fluid' alt={product.name} />
                </div>
                <div className='content text-center pb-3'>
                  <p className='bold h4'>{product.name}</p>
                </div>
              </motion.div>
            </Col>
          ))}
          <Col lg={12}>
            <div className='text-center py-3'>
              <Buttons label={<>View More Products</>} onClick={handleNavigate}></Buttons>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Sale Background Section */}
      <div className='salebg'>
        <div className='sale'>
          <Container>
            <Row>
              <Col lg={7} md={12} xs={12} className='py-3'>
                <div>
                  <h1 className='bold'>King Crackers</h1>
                  <p className='regular mediumfnt'>
                    We are the leading crackers wholesale manufacturers in Sivakasi, delivering crackers directly from Sivakasi at affordable prices.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={12} xs={12} className='py-3 align-self-center text-center'>
                <Buttons label={<>Shop Now . . . !</>} onClick={handleNavigate} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="floating-buttons">
        <a href="tel:+918637645383" className="floating-button call-button">
          <FaPhone size={24} />
        </a>
        <a href="https://wa.me/+919943743910" target="_blank" rel="noopener noreferrer" className="floating-button whatsapp-button">
          <FaWhatsapp size={24} />
        </a>
        <button onClick={handleNavigate} className="floating-button order-button">
          <FaShoppingCart size={24} />
          Order Now
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Home;
