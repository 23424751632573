import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.button`
  background: linear-gradient(135deg, #FFB22C 0%, #e72828 100%);
  font-size: 15px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  border: 0;
  border-radius: 8px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: #e72828;
    transition: transform 0.3s ease-in-out;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    z-index: 0;
  }

  &:hover {
    background: linear-gradient(135deg, #4a5c7c 0%, #6482AD 100%);
    transform: scale(1.05);
    box-shadow: 0 6px 12px #e72828;
  }

  &:active {
    transform: scale(0.98);
  }

  &:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }

  &:focus {
    outline: none;
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

const CloseStyle = styled.button`
  font-size: 15px;
  border: 0;
  color: #000;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6f61;
  }
`;

const Buttons = ({ label, onClick, fullWidth }) => {
  return (
    <ButtonStyle onClick={onClick} fullWidth={fullWidth}>
      <span>{label}</span>
    </ButtonStyle>
  );
};

const Close = ({ label, onClick }) => {
  return (
    <CloseStyle onClick={onClick}>
      {label}
    </CloseStyle>
  );
};

export { Buttons, Close };
