import React,{useEffect} from 'react'

const OrderPreview = () => {
  useEffect(() => {
    document.title = ' King Crackers | Order Details | Order Preview | ';
  }, []);
  return (
    <div>OrderPreview</div>
  )
}

export default OrderPreview