import React from 'react'
import AppBar from './components/AppBar'
import Footer from './components/Footer'
import { Col, Container, Row } from 'react-bootstrap'
import { Buttons } from '../components/Buttons/Buttons'
import { FaPuzzlePiece } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate();
    const handlenavigate = () => {
        navigate('/');
    }
  return (
    <>
      <AppBar />
      <img src={require('../assets/images/banner2.jpg')} className='img-fluid' alt='product name' />
      <Container className='padding'>
        <Row>
          <Col lg='6' className='py-3 align-self-center'>
            <h3 className='bold'>About Us</h3>
            <h4 className='bold'>King Crackers</h4>
            <div className='regular'>
              We are one of the leading crackers & fireworks manufacturers in Tamilnadu successfully functioning from 2011. We do both wholesale and retail business. Our main motive is to give the best products to our customers. We also focus on manufacturing highly safety crackers. We have 10 years of experience in the field of crackers and our main manufacturing industry is in Sivakasi.
            </div>
          </Col>
          <Col lg="6" className='py-3 text-center'>
            <img src={require('../assets/images/about.png')} className='img-fluid' alt='product'/>
          </Col>
        </Row>
      </Container>
      <div className='counter padding bg-overlay z'>
        <Container>
          <Row>
            <Col lg="12" className='py-3 text-center z'>
              <h4 className='bold text-white'>We have handpicked your favorite crackers in our pricelist..Please check it . . !!!</h4>
              <Buttons label={<>View More ... !</>} onClick={handlenavigate} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='padding'>
        <Row>
          <Col lg="4" md="6" xs="12" className='py-3'>
            <div className='text-center py-5'>
              <div className='choose'>
                <FaPuzzlePiece />
              </div>
              <h2 className="bold">Quality</h2>
              <p className="regular">Manufacturing Quality Crackers & innovation are the key behind our success.</p>
            </div>
            <div className='text-center py-5'>
              <div className='choose'>
                <ImPriceTag />
              </div>
              <h2 className="bold">Affordable Price</h2>
              <p className="regular">We are producing safe and compliant crackers with the highest quality at low prices.</p>
            </div>
          </Col>
          <Col lg="4" md="6" xs="12" className='align-self-center'>
            <img src={require('../assets/images/chutti.png')} className='img-fluid' alt='product'/>
          </Col>
          <Col lg="4" md="6" xs="12" className='py-3'>
            <div className='text-center py-5'>
              <div className='choose'>
                <FaPuzzlePiece />
              </div>
              <h2 className="bold">Safe To Use</h2>
              <p className="regular">Crackers we offer are safe and made from fine quality raw materials.</p>
            </div>
            <div className='text-center py-5'>
              <div className='choose'>
                <ImPriceTag />
              </div>
              <h2 className="bold">Satisfaction</h2>
              <p className="regular">Our quality and timely delivery have attracted customers easily.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='salebg py-3'>
        <Container>
          <Row>
            <Col lg="7" md="6" xs="12" className='py-3'>
              <h1 className="bold">King Crackers</h1>
              <p className="regular smallfnt">Crackers is a leading crackers Showroom Located at Sivakasi. We Supply Best Quality Crackers across the Country.</p>
              <div className="shop">
                <Buttons label={<>View Products . . . !</>} onClick={handlenavigate} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default About
