import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa'; // Changed icons
import { FaPhoneAlt } from 'react-icons/fa'; // Keep this if used
import "./footer.css";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className='footer-bg foot-pad'>
        <Container>
          <Row>
            <Col lg='12' className='border-top border-bottom py-4'>
              <Row>
                <Col lg='3' className='align-self-center py-3'>
                  <div className='ft-logo mx-auto'>
                    <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='Store Logo' />
                  </div>
                </Col>
                <Col lg='3' className='align-self-center py-3'>
                  <div className='d-flex align-items-center'>
                    <div className='ft-icon'>
                      <FaMapMarkerAlt /> {/* Changed to FaMapMarkerAlt */}
                    </div>
                    <div className='ft-details'>
                    D.No.210,Maruthu Pandiyar East Street, Sattur Main Road,<br />
                    Sivakasi - 626189
                    </div>
                  </div>
                </Col>
                <Col lg='3' className='align-self-center py-3'>
                  <div className='d-flex align-items-center'>
                    <div className='ft-icon'>
                      <FaEnvelope /> {/* Changed to FaEnvelope */}
                    </div>
                    <div className='ft-details'>
                      Email: kingcrackers@gmail.com
                    </div>
                  </div>
                </Col>
                <Col lg='3' className='align-self-center py-3'>
                  <div className='d-flex align-items-center'>
                    <div className='ft-icon'>
                      <FaPhone /> {/* Changed to FaPhone */}
                    </div>
                    <div className='ft-details'>
                      <div>+91 86376 45383</div>
                      <div>+91 63745 02354</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg='3' className='quick-links py-3'>
              <h3 className='bold py-3'>Quick Links</h3>
              <ul className='list-unstyled'>
                <li><Link to='/home'>Home</Link></li>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/'>Products</Link></li>
                <li><Link to='/safetytips'>Safety Tips</Link></li>
                <li><Link to='/contactus'>Contact Us</Link></li>
              </ul>
            </Col>
            <Col lg='9' className='py-3 align-self-center'>
              <div className='rules'>
                As per 2018 supreme court order, online sale of firecrackers are not permitted! We value our customers and at the same time, respect jurisdiction. We request you to add your products to the cart and submit the required crackers through the enquiry button. We will contact you within 24 hrs and confirm the order through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with King Crackers. Our License No.----. King Crackers as a company following 100% legal & statutory compliances and all our shops, go-downs are maintained as per the explosive acts. We send the parcels through registered and legal transport service providers as like every other major companies in Sivakasi is doing so.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='mark-bg'>
        <Container>
          <Row>
            <Col lg='12' className='py-3'>
              <div className='text-center regular'>Copyright © {currentYear}, King Crackers. All rights reserved</div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
