import React from 'react';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { CiLocationArrow1 } from "react-icons/ci";
import './AppBar.css'; // Import custom CSS file

const AppBar = () => {
  return (
    <>
      <div className='header-top'>
        <Container>
          <Row className='py-2 align-items-center'>
            <Col lg="4" className='d-lg-block d-none'>
              <div className="address">
                <CiLocationArrow1 className="icon-location" />
                <span>D.No.210,Maruthu Pandiyar East Street, Sattur Main Road, Sivakasi - 626189</span>
              </div>
            </Col>
            <Col lg='8'>
              <Marquee pauseOnHover={true} direction="left" speed={50} className="marquee-text">
                Diwali sale is open now. Please buy early to get best discounts. Happy Diwali...!!!! | +91 86376 45383 | +91 63745 02354
              </Marquee>
            </Col>
          </Row>
        </Container>
      </div>
      
      <Navbar expand="lg" className="header-navbar">
        <Container>
          <Navbar.Brand href="#home">
            <div className='logo'>
              <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='Greep Crackers' />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto text-center">
              <Nav.Link as={NavLink} exact to={"/home"}>Home</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/about"} >About</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/"} >Products</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/safetytips"} >Safetytips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contact"} >Contact</Nav.Link>
            </Nav>
            <Nav.Link as={NavLink} exact to={"/"} className='contact-link'>FOR QUERIES & BULK ORDER</Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AppBar;
